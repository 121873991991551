import React from "react";
import * as d3 from "d3";
import { css } from "@emotion/css";
import { useStyles2, useTheme2 } from "@grafana/ui";
import { getColorByHealthStatus } from "../../api/constants";
import { HealthNode } from "../types";
import { nodeIndent, maxNodeHeight, margin } from "./layoutHelper";

interface LinkProps {
  source: [number, number];
  target: [number, number];
  data: HealthNode;
}

interface VerticalLinkProps {
  source: d3.HierarchyPointNode<HealthNode>;
  target: d3.HierarchyPointNode<HealthNode>;
}

const getStyles = () => ({
  link: css`
    fill: none;
    stroke: #ccc;
    stroke-width: 1.5px;
  `,
});

export const VerticalLink = ({ source, target }: VerticalLinkProps) => {
  const styles = useStyles2(getStyles);
  const ind = source.data.children.findIndex((c) => c.id === target.data.id);
  let sourceInd = 1;
  if (ind > 0 && source.children) {
    const preInd = source.children[ind - 1].data.preOrderIndex;
    sourceInd = preInd === undefined ? 1 : preInd;
  } else {
    sourceInd = source.data.preOrderIndex === undefined ? 1 : source.data.preOrderIndex;
  }
  let horizontalMargin = 0;
  if (target.children && target.children.length > 0) {
    horizontalMargin = margin;
  }
  let verticalMargin = 0;
  if (source.children && source.children.length > 0 && ind === 0) {
    verticalMargin = margin;
  }
  const targetInd = target.data.preOrderIndex === undefined ? 1 : target.data.preOrderIndex;
  const diag = `
    M${source.depth * nodeIndent},${sourceInd * maxNodeHeight + verticalMargin}
    V${targetInd * maxNodeHeight}
    h${nodeIndent - horizontalMargin}
  `;

  const theme = useTheme2();
  const stroke = theme.colors.text.disabled;
  return <path className={styles.link} d={diag} style={{ stroke: stroke }}></path>;
};

export const Link = ({ source, target, data }: LinkProps) => {
  const styles = useStyles2(getStyles);
  const diag = d3.linkHorizontal()({
    source: source,
    target: target,
  });

  const stroke = getColorByHealthStatus(data.healthStatus);
  return <path className={styles.link} d={diag || ""} style={{ stroke: stroke }}></path>;
};
