import { trackException } from "appInsights";
import React, { Component, ErrorInfo } from "react";
import { v4 } from "uuid";

interface Props {
  children: React.ReactNode;
}

interface State {
  error:
    | {
        value: Error;
        info: ErrorInfo;
      }
    | false;
  exceptionId: string | null;
}

export class ErrorBoundary extends Component<Props, State> {
  override state: State = {
    error: false,
    exceptionId: null,
  };

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const exceptionId = v4();
    trackException({
      id: exceptionId,
      exception: error,
      properties: {
        reporter: "ErrorBoundary.componentDidCatch",
        errorInfo,
      },
    });
    this.setState({ error: { value: error, info: errorInfo }, exceptionId });
  }

  override render() {
    if (this.state.error !== false) {
      return (
        <div className="panel-empty">
          <p>
            Unexpected problem encountered. This incident has been reported{" "}
            {this.state.exceptionId && <>(exceptionId: {this.state.exceptionId})</>}. Try refreshing the page.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
