import { DataSourceInstanceSettings } from "@grafana/data";
import { healthLoader } from "api/health/healthLoader";
import { ContextMenuItem, HealthNode } from "panel/types";
import { CustomLinkConfiguration, GenevaJsonData } from "types";
import { getMetric, getMonitorId, getNamespace, isV2Monitor } from "util/monitorUtils";
import { getEnrichment } from "./customLinkUtils";
import { convertMonitorFrameToTree, convertNodeFrameToTree } from "./healthNodeUtils";

export const loadResources = async (
  node: HealthNode,
  account: string,
  settings: DataSourceInstanceSettings<GenevaJsonData>,
  stamp?: string
): Promise<HealthNode> => {
  const response = await healthLoader.loadTopologyDataFrame({
    dataSourceInstanceSettings: settings,
    account: account ?? "",
    node: node.id,
    stamp,
  });

  return convertNodeFrameToTree(response);
};

export const loadMonitors = async (
  node: HealthNode,
  account: string,
  settings: DataSourceInstanceSettings<GenevaJsonData>,
  stamp?: string
): Promise<HealthNode[]> => {
  const response = await healthLoader.loadMonitorsTopolgyDataFrame({
    dataSourceInstanceSettings: settings,
    account: account ?? "",
    resourceType: node.resourceType,
    resourceName: node.resourceName,
    stamp,
  });
  return convertMonitorFrameToTree(response);
};

export const getMonitorEnrichments = async (
  node: HealthNode,
  account: string,
  settings: DataSourceInstanceSettings<GenevaJsonData>
): Promise<ContextMenuItem[]> => {
  const customLinks = isV2Monitor(node.monitorMetadata)
    ? await loadV2MonitorConfig(node, account, settings)
    : await loadV1MonitorConfig(node, account, settings);

  const links: ContextMenuItem[] = [];
  for (const link of customLinks) {
    const enrichment = getEnrichment(link, node);
    if (enrichment) {
      links.push(enrichment);
    }
  }
  return links;
};

const loadV1MonitorConfig = async (
  node: HealthNode,
  account: string,
  settings: DataSourceInstanceSettings<GenevaJsonData>
): Promise<CustomLinkConfiguration[]> => {
  const configs = await healthLoader.monitorConfigv1.load({
    dataSourceInstanceSettings: settings,
    account: account ?? "",
    component: getNamespace(node.monitorMetadata),
    event: getMetric(node.monitorMetadata),
  });
  const config = configs.find((c) => c.id === getMonitorId(node.monitorMetadata));
  return (config?.customLinks || []) ?? [];
};

const loadV2MonitorConfig = async (
  node: HealthNode,
  account: string,
  settings: DataSourceInstanceSettings<GenevaJsonData>
): Promise<CustomLinkConfiguration[]> => {
  const config = await healthLoader.monitorConfigv2.load({
    dataSourceInstanceSettings: settings,
    account: account ?? "",
    monitorId: getMonitorId(node.monitorMetadata),
  });
  return config.alertEnrichmentConfiguration.customLinks;
};
