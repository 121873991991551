import { CustomLinkConfiguration } from "types";

interface GrafanaConfiguration extends CustomLinkConfiguration {
  /**
   *  Grafana workspace instance URL
   */
  instanceUrl: string;
  /**
   *  Grafana dashboard id
   */
  dashboardId: string;
  /**
   *  Grafana dashboard variables
   */
  variables: string[][];
  /**
   *  Grafana dashboard parameters, such as time range {{"from", "now-1h"}}
   */
  parameters: string[][];
  /**
   *  Generate Grafana link
   */
  generatedLink?: string;
}

export const getGrafanaLink = (config: CustomLinkConfiguration) => {
  const grafanConfig = config as GrafanaConfiguration;
  // Remove '/' if instanceUrl ends with it because Grafana doesn't know how to handle `//`
  if (grafanConfig.instanceUrl.slice(-1) === "/") {
    grafanConfig.instanceUrl = grafanConfig.instanceUrl.slice(0, grafanConfig.instanceUrl.length - 1);
  }
  return `${grafanConfig.instanceUrl}/d/${grafanConfig.dashboardId}`;
};
