import { HealthNode } from "panel/types";
import { CustomLinkConfiguration } from "types";
import { duration } from "moment";
import { getMonitorId } from "util/monitorUtils";
import { encodeParams } from "../customLinkUtils";
import { doubleEncode } from "util/dataUtil";

interface DashboardLinkConfiguration extends CustomLinkConfiguration {
  dimensionsToOverride: string[];
  lookbackDuration: string;
  accountName: string;
  path: string[];
}

const serializeWires = (wires: unknown) => {
  return JSON.stringify(wires).replace(/%/gi, "%25").replace(/#/gi, "%23").replace(/&/gi, "%26");
};

export const getDashboardLink = (config: CustomLinkConfiguration, node: HealthNode) => {
  const dashboardConfig = config as DashboardLinkConfiguration;
  const monitorMetadata = node.monitorMetadata;
  const valueLookup = Object.keys(monitorMetadata).reduce((result, value) => {
    result.set(value.toLowerCase(), monitorMetadata[`${value}`]);
    return result;
  }, new Map<string, string | number | boolean>());
  const query: { [key: string]: string } = {};
  const overridesQueryParam = dashboardConfig.dimensionsToOverride.map((dim: string) => {
    let replacement = `${monitorMetadata.dimensionPrefix}${dim}`.toLowerCase();

    if (valueLookup && valueLookup.get(replacement)) {
      replacement = valueLookup.get(replacement)?.toString() ?? "";
    } else {
      replacement = `{${replacement}}`;
    }

    return {
      query: `//*[id='${dim}']`,
      key: "value",
      replacement: replacement,
    };
  });
  if (overridesQueryParam && overridesQueryParam.length > 0) {
    query["overrides"] = serializeWires(overridesQueryParam);
  }
  const timeRange = duration(dashboardConfig.lookbackDuration).asMilliseconds();
  if (timeRange) {
    query["globalStartTime"] = `-${timeRange}`;
    query["globalEndTime"] = `-1`;
    query["pinGlobalTimeRange"] = "true";
  }
  const monitorId = getMonitorId(node.monitorMetadata);
  query["monitor.id"] = monitorId;
  const params = encodeParams(query);
  const stringPath = [dashboardConfig.accountName]
    .concat(dashboardConfig.path)
    .map((part) => doubleEncode(part))
    .join("/");
  return `https://portal.microsoftgeneva.com/dashboard/${stringPath}?${params}`;
};
