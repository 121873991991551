import { css } from "@emotion/css";
import { dateTime, GrafanaTheme2, TimeRange } from "@grafana/data";
import {
  Alert,
  Button,
  ErrorBoundary,
  HorizontalGroup,
  IconButton,
  Label,
  LoadingPlaceholder,
  TimeRangeInput,
  useStyles2,
  VerticalGroup,
} from "@grafana/ui";
import { healthLoader } from "api/health/healthLoader";
import { trackPageView } from "appInsights";
import moment, { duration } from "moment";
import { HealthNode } from "panel/types";
import React, { useEffect, useState } from "react";

const getStyles = (theme: GrafanaTheme2) => ({
  scroller: css({
    minHeight: "35vh",
    maxHeight: "50vh",
    display: "flex",
    flexDirection: "column",
  }),
  selection: css({
    fontSize: theme.typography.h5.fontSize,
    fontFamily: theme.typography.h5.fontFamily,
    color: theme.colors.text.primary,
    fontWeight: "normal",
  }),
  buttons: css({
    position: "absolute",
    bottom: 0,
    marginBottom: "20px",
    overflow: "hidden",
  }),
  suppressionProperties: css({
    overflow: "auto",
    flex: "1",
  }),
  propertiesGroup: css({
    marginTop: "10px",
  }),
  note: css({
    color: theme.colors.text.secondary,
    fontSize: "12px",
    fontWeight: 500,
  }),
});

interface SuppressProps {
  onApply: (timeRange: TimeRange) => Promise<void>;
  onCancel: () => void;
}

export const Suppress = ({ onApply, onCancel }: SuppressProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const styles = useStyles2(getStyles);
  const now = dateTime();
  const defaultTimeRange: TimeRange = {
    from: now,
    to: dateTime(now).add(6, "hour"),
    raw: {
      from: "now",
      to: "now+6h",
    },
  };
  const [timeRange, setTimeRange] = useState<TimeRange>(defaultTimeRange);
  useEffect(() => {
    trackPageView({ name: "Suppress" });
  }, []);
  const onClick = async () => {
    setIsLoading(true);
    await onApply(timeRange);
    setIsLoading(false);
  };
  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return <Alert title={error.message} />;
        }
        return (
          <div className={styles.scroller}>
            <div className={styles.selection}>Expiration Time</div>
            <VerticalGroup>
              <TimeRangeInput value={timeRange} onChange={(range) => setTimeRange(range)} hideQuickRanges={true} />
            </VerticalGroup>
            {isLoading && <LoadingPlaceholder className={styles.suppressionProperties} text="Loading..." />}
            <div className={styles.buttons}>
              <HorizontalGroup justify="flex-start">
                <Button onClick={onClick} disabled={isLoading}>
                  Suppress
                </Button>
                <Button onClick={onCancel} variant="secondary" disabled={isLoading}>
                  Cancel
                </Button>
                <span className={styles.note}>Note: it may take a moment for rule changes to be processed.</span>
              </HorizontalGroup>
            </div>
          </div>
        );
      }}
    </ErrorBoundary>
  );
};

interface UnsuppressProps {
  account?: string;
  node: HealthNode;
  onApply: () => Promise<void>;
  onCancel: () => void;
}

export const Unsuppress = ({ onApply, onCancel, account, node }: UnsuppressProps) => {
  const styles = useStyles2(getStyles);
  const [isReinstating, setIsReinstating] = useState<boolean>(false);
  const query = healthLoader.suppressionRules.useQuery(account, node.id);
  const { data, isLoading, isRefetching, isSuccess } = query;
  const ruleProperties = new Map<string, string>();
  const dimensionProperties = new Map<string, string>();
  if (data) {
    const rule = data;
    ruleProperties.set("Creator", rule.alias);
    const time = moment(rule.creationTime);
    ruleProperties.set("Creation Time", time.format("MM/DD/YYYY hh:mm:ssa"));

    const ruleDuration = duration(rule.expiresAfter);
    time.add(ruleDuration);
    ruleProperties.set("Expiration Time", `${time.format("MM/DD/YYYY hh:mm:ssa")} (${time.fromNow()})`);
    ruleProperties.set("Targeted Resource Types", (rule.resourceTypes || []).join(" "));

    Object.keys(rule.rule).forEach((k) => {
      dimensionProperties.set(k, rule.rule[`${k}`]);
    });
  }
  useEffect(() => {
    trackPageView({ name: "Unsuppress" });
  }, []);
  const onClick = async () => {
    setIsReinstating(true);
    await onApply();
    setIsReinstating(false);
  };
  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return <Alert title={error.message} />;
        }
        return (
          <div className={styles.scroller}>
            <Alert
              style={{ flex: "0" }}
              title=" Alerting is currently suppressed on the resource and its children."
              severity="warning"
            />
            {(isLoading || isRefetching || isReinstating) && (
              <LoadingPlaceholder className={styles.suppressionProperties} text="Loading..." />
            )}
            {!isLoading && !isRefetching && !isReinstating && isSuccess && (
              <div className={styles.suppressionProperties}>
                <div className={styles.propertiesGroup}>
                  <div>
                    <IconButton
                      style={{ float: "right" }}
                      name="sync"
                      color="blue"
                      onClick={() => {
                        query.refetch();
                      }}
                      aria-label="loading suppression rules spinner"
                    ></IconButton>
                    <h5>Suppression rules</h5>
                  </div>
                  {[...ruleProperties.keys()].map((key) => (
                    <Label description={ruleProperties.get(key)}>{key}</Label>
                  ))}
                </div>
                {dimensionProperties && dimensionProperties.size > 0 && (
                  <div className={styles.propertiesGroup}>
                    <h6>Targeted Resource Dimensions</h6>
                    {[...dimensionProperties.keys()].map((key) => (
                      <Label description={dimensionProperties.get(key)}>{key}</Label>
                    ))}
                  </div>
                )}
              </div>
            )}
            <div>
              <HorizontalGroup justify="flex-start">
                <Button onClick={onClick} disabled={isReinstating}>
                  Reinstate
                </Button>
                <Button onClick={onCancel} variant="secondary" disabled={isReinstating}>
                  Cancel
                </Button>
                <span className={styles.note}>Note: it may take a moment for rule changes to be processed.</span>
              </HorizontalGroup>
            </div>
          </div>
        );
      }}
    </ErrorBoundary>
  );
};
