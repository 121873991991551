import { CustomLinkConfiguration } from "types";
import { encodeParams } from "../customLinkUtils";

interface KustoCustomLinkConfiguration extends CustomLinkConfiguration {
  endpoint: string;
  database: string;
  query: string;
}

export const getKustoLink = (config: CustomLinkConfiguration, monitorId?: string) => {
  const kustoConfig = config as KustoCustomLinkConfiguration;
  const kustoParams: { [key: string]: string } = {
    kustoUrl: kustoConfig.endpoint,
    database: kustoConfig.database,
    query: kustoConfig.query,
  };
  if (monitorId) {
    kustoParams["monitor.id"] = monitorId;
  }
  const params = encodeParams(kustoParams, minimalEncodeURIComponent);
  return `https://portal.microsoftgeneva.com/?page=logs&be=kusto&${params}`;
};

/**
 * Encode a small set of characters that would result in misunderstanding of query parameters
 *
 * Context: We don't encodeURIComponent for the query parameter keys and values for readibility; we use this function instead.
 * For example:
 * This link: ?overrides=[{"query":"//*[id=%27RoleInstance%27]","key":"value","replacement":"Metrics.Auxiliary_IN_0"}]%20
 * Without encodeURIComponent, we can easily read the JSON object.
 *
 * For the decoding, use decodeURIComponent.
 */
const minimalEncodeURIComponent = (raw: string | number | boolean): string => {
  return (
    raw
      .toString()
      //encode characters that would result in misunderstanding of query parameters
      .replace(/%/gi, "%25")
      .replace(/#/gi, "%23")
      .replace(/&/gi, "%26")
      .replace(/=/gi, "%3D")
      //also replace spaces to make links readable by outlook
      .replace(/ /gi, "%20")
      //also replace "+" with "%2B" so it isn't decoded to " "
      .replace(/\+/gi, "%2B")
      // `\n` must be encoded (Example: DGrep query)
      .replace(/\n/gi, "%0A")
  );
};
