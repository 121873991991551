import _ from "lodash";
import { HealthNode, SuppressionMode, SuppressionReason } from "panel/types";

const pageSize = 10;

interface Metadata {
  page: number;
  totalPages: number;
  children: HealthNode[];
}

const cache = new Map<string, Metadata>();

const addLoadMoreNode = (parentId: string, nodes: HealthNode[]) => {
  nodes.push({
    id: _.uniqueId(parentId),
    name: "",
    displayName: "",
    healthStatus: -1,
    hasChildren: false,
    hasResourceId: false,
    resourceType: "",
    resourceName: "",
    children: [],
    parentId: parentId,
    type: "loadMore",
    suppressionState: SuppressionMode.Unsuppress,
    suppressionReason: SuppressionReason.Own,
    monitorMetadata: {},
  });
};

export const loadMore = (id: string, expandAll = false): HealthNode[] => {
  const metadata = cache.get(id);
  if (metadata) {
    const end = ++metadata.page * pageSize;
    if (expandAll || end >= metadata.children.length) {
      return metadata.children;
    } else {
      const loadedNodes = metadata.children.slice(0, end);
      addLoadMoreNode(id, loadedNodes);
      return loadedNodes;
    }
  }
  return [];
};

export const addNode = (id: string, children: HealthNode[], expandAll = false): HealthNode[] => {
  if (id && children && children.length > 0) {
    for (const child of children) {
      if (child.type === "folder") {
        cache.set(child.id, {
          page: 0,
          totalPages: child.children.length / pageSize,
          children: child.children,
        });
        if (!expandAll) {
          child.children = [];
        }
      }
    }
    const metadata: Metadata = {
      page: 0,
      totalPages: children.length / pageSize,
      children: children,
    };
    cache.set(id, metadata);
    return loadMore(id, expandAll);
  }
  return [];
};

export const getLoadMoreString = (id: string): string => {
  const metadata = cache.get(id);
  if (metadata) {
    const remaining = metadata.children.length - metadata.page * pageSize;
    return `Load More (${remaining})`;
  }
  return "Load More";
};

export const resetNode = (id: string) => {
  const metadata = cache.get(id);
  if (metadata) {
    metadata.page = 0;
  }
};

export const has = (id: string): boolean => {
  return cache.has(id);
};

export const clearCache = () => {
  cache.clear();
};
