import { DataSourceInstanceSettings } from "@grafana/data";
import { ContextMenuItem, HealthNode, SuppressionMode } from "panel/types";
import { GenevaJsonData } from "types";
import * as utils from "../../util/monitorUtils";
import { getMonitorEnrichments } from "./apiShim";

export const SuppressKey = "suppress";

export const getEnrichments = async (
  node: HealthNode,
  account: string,
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>
) => {
  if (node.type === "monitor") {
    return await getMonitorEnrichments(node, account, dataSourceInstanceSettings);
  }
  return [];
};

export const getDefaultMenuItems = (node: HealthNode) => {
  if (node.type === "monitor") {
    const menuItems = defaultItems.slice(1);
    return menuItems;
  } else {
    return defaultItems;
  }
};

const defaultItems: ContextMenuItem[] = [
  {
    key: SuppressKey,
    label: "Suppress",
    icon: "volume-mute",
    onClick: () => {
      //setShowSuppress(true);
    },
    getLabel: (node?: HealthNode) => {
      return node && node.suppressionState === SuppressionMode.Unsuppress ? "Suppress" : "Reinstate";
    },
    getIcon: (node?: HealthNode) => {
      return node && node.suppressionState === SuppressionMode.Unsuppress ? "volume-mute" : "volume";
    },
  },
  {
    key: "manage",
    label: "Manage Monitors",
    icon: "clipboard-notes",
    onClick: undefined,
    getLabel: (node?: HealthNode) => {
      return node && node.type === "monitor" ? "Edit Monitor" : "Manage Monitors";
    },
    getUrl: (account: string, node?: HealthNode) => {
      if (node && node.type === "monitor") {
        const monitorId = utils.getMonitorId(node.monitorMetadata);
        const params: { [key: string]: string } = {
          page: "settings",
          mode: "mdm",
          account: account,
        };

        if (utils.isV2Monitor(node.monitorMetadata)) {
          params.tab = "monitorsV2";
          params.activity = "edit-monitor";
          params.version = "1";
          params.monitorId = monitorId;
        } else {
          // namespace and metric do not apply for health monitors, which belong to a resource
          // type, not a metric
          if (utils.getDataSourceType(node.monitorMetadata) !== "Health") {
            params.namespace = utils.getNamespace(node.monitorMetadata);
            params.metric = utils.getMetric(node.monitorMetadata);
          }

          params.tab = "monitors";
          params.monitor = monitorId;
        }
        const encodedParams: string[] = [];
        for (const key in params) {
          const encodedKey = encodeURIComponent(key);
          const encodedValue = encodeURIComponent(params[`${key}`]);
          encodedParams.push(`${encodedKey}=${encodedValue}`);
        }
        const joinedParams = encodedParams.join("&");
        return `https://portal.microsoftgeneva.com?${joinedParams}`;
      } else {
        return `https://portal.microsoftgeneva.com/manage/monitors?account=${account}`;
      }
    },
    linkTarget: "_blank",
  },
  {
    key: "addV2",
    label: "Add V2 Monitor",
    icon: "plus",
    getUrl: (account: string) => {
      return `https://portal.microsoftgeneva.com/manage/monitors/monitor?activity=edit-monitor&version=1&action=1&account=${account}`;
    },
    linkTarget: "_blank",
  },
  {
    key: "addOrEditResource",
    label: "Add Resource Type",
    icon: "edit",
    getLabel: (node?: HealthNode) => {
      if (node && node.hasResourceId) {
        return "Edit Resource Type";
      }
      return "Add Resource Type";
    },
    getIcon: (node?: HealthNode) => {
      return node && node.hasResourceId ? "edit" : "plus";
    },
    getUrl: (account: string, node?: HealthNode) => {
      return node && node.hasResourceId
        ? `https://portal.microsoftgeneva.com/account/metrics?account=${account}&section=resourceTypes&resourceType=${node.resourceType}`
        : `https://portal.microsoftgeneva.com/account/metrics?account=${account}&section=resourceTypes`;
    },
    linkTarget: "_blank",
  },
];
