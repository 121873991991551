import { css, cx } from "@emotion/css";
import { GrafanaTheme2 } from "@grafana/data";
import { Icon, LoadingPlaceholder, Tooltip, useTheme2 } from "@grafana/ui";
import { Folder } from "panel/icons/folder";
import { Monitor } from "panel/icons/monitor";
import React, { useRef, useState } from "react";
import { isSilentMonitor } from "util/monitorUtils";
import {
  GenevaHealthColors,
  getBackgroundColorByOrientation,
  getColorByHealthStatus,
  getHoverBackgroundColorByOrientation,
} from "../../api/constants";
import { HealthNode, LayoutOrientation, Point, SuppressionMode } from "../types";
import { chevronRadius, margin, nodeHeight, verticalChevron, verticalExpandedChevron } from "./layoutHelper";

const maxNodeWidth = "300px";

const getStyles = (
  theme: GrafanaTheme2,
  orientation: LayoutOrientation,
  healthStatus?: number,
  isSelected?: boolean,
  isRightClicked?: boolean
) => ({
  node: css({
    display: "inline-block",
    cursor: "pointer",
    height: "98%", //setting this to 100% leaves ghost border lines around the foreignObject durin pan and zoom
    borderRadius: "0.3em",
    border: isSelected ? `${GenevaHealthColors.Selected} solid 2px` : "",
    color: theme.colors.text.primary,
    background: isRightClicked
      ? getHoverBackgroundColorByOrientation(orientation, theme)
      : getBackgroundColorByOrientation(orientation, theme),
    "&:hover": {
      background: getHoverBackgroundColorByOrientation(orientation, theme),
    },
    maxWidth: maxNodeWidth,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginBottom: "-1px",
  }),
  notch: css({
    borderLeft: `${getColorByHealthStatus(healthStatus)} solid 5px`,
  }),
  nodeText: css({
    display: "inline",
    fontSize: theme.typography.h5.fontSize,
    fontFamily: theme.typography.h5.fontFamily,
    color: theme.colors.text.primary,
    fontWeight: "normal",
    textAlign: "center",
    marginRight: "5px",
    marginLeft: "5px",
    lineHeight: 1.8,
  }),
  loadingWrapper: css({
    textAlign: "center",
    color: theme.colors.text.secondary,
    marginRight: "5px",
    marginLeft: "5px",
    lineHeight: 1.8,
  }),
  error: css({
    display: "flex",
    height: "100%",
  }),
  errorIcon: css({
    background: theme.colors.error.main,
    color: theme.colors.error.contrastText,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0.125),
    width: "30px",
  }),
  errorText: css({
    display: "inline",
    fontSize: theme.typography.body.fontSize,
    fontFamily: theme.typography.body.fontFamily,
    color: theme.colors.text.primary,
    fontWeight: "normal",
    textAlign: "center",
    margin: "2px 5px 0 5px",
  }),
  suppress: css({
    marginLeft: "3px",
    color: theme.colors.text.link,
  }),
  monitor: css({
    marginRight: "3px",
    color: getColorByHealthStatus(healthStatus),
  }),
  svgIcon: css({
    display: "inline-block",
    marginRight: "5px",
  }),
  chevron: css({
    stroke: theme.colors.text.disabled,
    fill: "none",
    strokeWidth: 2,
    cursor: "pointer",
  }),
});

interface NodeChevronProps {
  node: HealthNode;
  orientation: LayoutOrientation;
  color: string;
  theme: GrafanaTheme2;
  onClick: () => void;
}

const NodeChevron = ({ node, orientation, color, theme, onClick }: NodeChevronProps) => {
  if (node.type === "monitor") {
    return null;
  }
  const isExpanded = node.children && node.children.length > 0;
  const styles = getStyles(theme, orientation);
  return (
    <>
      {orientation === "horizontal" ? (
        <circle data-testid={"node-horizontal-chevron"} r={chevronRadius} fill={color} cursor="pointer"></circle>
      ) : isExpanded ? (
        <path
          data-testid={"node-verticalexpanded-chevron"}
          className={styles.chevron}
          d={verticalExpandedChevron}
          onClick={onClick}
        />
      ) : (
        <path data-testid={"node-vertical-chevron"} className={styles.chevron} d={verticalChevron} onClick={onClick} />
      )}
    </>
  );
};

const nodeContent = (styles: { nodeText: string; suppress: string; svgIcon: string }, data: HealthNode) => (
  <div className={styles.nodeText} title={data.displayName || data.name}>
    {data.type === "folder" && (
      <div data-testid="node-folder-icon" className={styles.svgIcon}>
        <Folder />
      </div>
    )}
    {data.type === "monitor" && (
      <div data-testid="node-monitor-icon" className={styles.svgIcon}>
        <Monitor />
      </div>
    )}
    <span>{data.displayName || data.name}</span>
    {data.suppressionState === SuppressionMode.Suppress && (
      <Icon
        data-testid="node-suppress-icon"
        className={styles.suppress}
        // not all icons from unicons have been added to the type of name field
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name={"volume-mute" as any}
        size="md"
      ></Icon>
    )}
  </div>
);
interface NodeProps {
  id: string;
  node: d3.HierarchyPointNode<HealthNode>;
  x: number;
  y: number;
  isSelected: boolean;
  isRightClicked: boolean;
  orientation: LayoutOrientation;
  onNodeClick: (node: d3.HierarchyPointNode<HealthNode>) => Promise<void>;
  showContextMenu: (node: HealthNode, point: Point) => void;
}

export const Node = ({
  id,
  node,
  x,
  y,
  isSelected,
  isRightClicked,
  orientation,
  onNodeClick,
  showContextMenu,
}: NodeProps) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme2();
  const styles = getStyles(theme, orientation, node.data.healthStatus, isSelected, isRightClicked);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const onClick = async () => {
    setIsLoading(true);
    try {
      await onNodeClick(node);
      setIsError(false);
    } catch (_) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      await onClick();
    }
  };

  const onContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (node.data.type !== "folder") {
      showContextMenu(node.data, { x: e.clientX, y: e.clientY });
    }
  };
  const color = getColorByHealthStatus(node.data.healthStatus);
  const xOffset = orientation === "vertical" && node.data.type !== "monitor" ? margin : 0;
  const showTooltip = node.data.type === "monitor" && isSilentMonitor(node.data.monitorMetadata);
  const nodeClass = cx(styles.node, styles.notch);

  return (
    <g key={id} transform={`translate(${y}, ${x})`}>
      <NodeChevron
        node={node.data}
        orientation={orientation}
        color={color}
        theme={theme}
        onClick={onClick}
      ></NodeChevron>
      <foreignObject key={id} height={`${nodeHeight}`} width={maxNodeWidth} x={xOffset} y={-12.5}>
        <div
          ref={divRef}
          data-testid="tree-node"
          className={nodeClass}
          onClick={onClick}
          onContextMenu={onContextMenu}
          tabIndex={0}
          onKeyDown={onKeyDown}
        >
          {isLoading ? (
            <div className={styles.loadingWrapper}>
              <LoadingPlaceholder text={"Loading resources..."} />
            </div>
          ) : isError ? (
            <div className={styles.error}>
              <div className={styles.errorIcon}>
                <Icon name={"exclamation-triangle"} size="lg"></Icon>
              </div>
              <div className={styles.errorText}>Error! Click to retry.</div>
            </div>
          ) : showTooltip ? (
            <Tooltip
              content={
                <div data-testid="tooltip">
                  <h6>Non-MDM Monitor</h6>
                  <span>
                    As this monitor is not computed in MDM, we are unable to render an evaluation chart for it.
                  </span>
                </div>
              }
              theme="info"
              placement="bottom"
            >
              {nodeContent(styles, node.data)}
            </Tooltip>
          ) : (
            nodeContent(styles, node.data)
          )}
        </div>
      </foreignObject>
    </g>
  );
};
