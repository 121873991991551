import { css } from "@emotion/css";
import { Warnings } from "components/Warnings";
import { useWarning } from "components/hooks/useWarning";
import * as d3 from "d3";
import _ from "lodash";
import { TreeStore } from "panel/stores/TreeStore";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getMonitorId } from "util/monitorUtils";
import { HealthNode, LayoutOrientation, Point } from "../types";
import * as CacheHelper from "./CacheHelper";
import { Link, VerticalLink } from "./Link";
import { LoadMoreNode } from "./LoadMoreNode";
import { Node } from "./Node";
import { getRootGroupTransform, layout } from "./layoutHelper";

interface TreeProps {
  data: HealthNode;
  account: string;
  height: number;
  width: number;
  orientation: LayoutOrientation;
  selectNodeByVars: string;
  loadNodeData: (node: HealthNode, isLeafNode?: boolean) => Promise<HealthNode>;
  showContextMenu: (node: HealthNode, point: Point) => void;
  hideContextMenu: () => void;
  onNodeClicked: (node: HealthNode) => void;
  trackEvent: (name: string, properties?: { [key: string]: string }) => void;
}

const getStyles = () => ({
  horizontal: css({
    overflow: "hidden",
  }),
  vertical: css({
    overflowX: "hidden",
    overflowY: "scroll",
  }),
});

export const Tree = ({
  account,
  data,
  height,
  width,
  loadNodeData,
  showContextMenu,
  hideContextMenu,
  orientation,
  onNodeClicked,
  trackEvent,
  selectNodeByVars,
}: TreeProps) => {
  const d3Ref = useRef<SVGSVGElement | null>(null);
  const svgGroupRef = useRef<SVGGElement | null>(null);
  const chartDiv = useRef<HTMLDivElement | null>(null);
  const [nodes, setNodes] = useState<d3.HierarchyPointNode<HealthNode>[]>([]);
  const [links, setLinks] = useState<d3.HierarchyPointLink<HealthNode>[]>([]);
  const [loadMoreNodes, setLoadMoreNodes] = useState<d3.HierarchyPointNode<HealthNode>[]>([]);
  const [selectedNode, setSelectedNode] = useState<d3.HierarchyPointNode<HealthNode>>();
  const [selectedNodeById, setSelectedNodeById] = useState<string>();
  const [treeHeight, setTreeHeight] = useState<number>(height);
  const { warnings, setWarnings, removeWarning } = useWarning();

  const {
    state: { rightClickedNodeRefresh: rightClickedNode, updateSuppressionNode },
  } = TreeStore.useState();
  const rootData = useMemo(() => {
    setLinks([]);
    setNodes([]);
    setLoadMoreNodes([]);
    const rootData = data;
    rootData.children = CacheHelper.addNode(rootData.id, rootData.children);
    return rootData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id, account]);

  const buildLayout = useCallback((): [d3.HierarchyPointNode<HealthNode>[], d3.HierarchyPointNode<HealthNode>] => {
    const [allNodes, nodes, loadMoreNodes, links, root, _height] = layout(width, height, rootData, orientation);
    setNodes(nodes);
    setLoadMoreNodes(loadMoreNodes);
    setLinks(links);
    setTreeHeight(_height);
    return [allNodes, root as d3.HierarchyPointNode<HealthNode>];
  }, [height, orientation, rootData, width]);

  useEffect(() => {
    const [allNodes, root] = buildLayout();
    if (selectedNode && selectedNode.data && selectedNode.data.id && selectedNode.parent) {
      //Find the selected in the newly created tree because with the
      //addition/removal of children node positions have been recalculated
      const findNode = _.first(allNodes.filter((n) => n.data.id === selectedNode.data.id));
      //Center on the new position of the selected node or fallback to center on root.
      findNode ? centerNode(findNode) : centerNode(root as d3.HierarchyPointNode<HealthNode>);
    } else if (selectNodeByVars) {
      const findNode = _.first(
        allNodes.filter((n) => {
          if (n.data.type === "monitor") {
            try {
              return getMonitorId(n.data.monitorMetadata) === selectNodeByVars;
            } catch (e) {
              setWarnings((prevWarning) => ({
                ...prevWarning,
                monitorId: {
                  order: 0,
                  message: (e as Error).message,
                },
              }));
              return n.data.id === selectNodeByVars;
            }
          } else {
            return n.data.id === selectNodeByVars;
          }
        })
      );
      setSelectedNode(findNode);
      findNode ? centerNode(findNode) : centerNode(root as d3.HierarchyPointNode<HealthNode>);
    } else {
      centerNode(root as d3.HierarchyPointNode<HealthNode>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode, data.id, account, updateSuppressionNode, orientation, rightClickedNode.id, selectedNodeById]);

  useEffect(() => {
    const [allNodes, root] = buildLayout();
    const findNode = _.first(allNodes.filter((n) => n.data.id === selectedNodeById));
    findNode ? centerNode(findNode) : centerNode(root);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodeById]);

  const zoomed = useCallback(
    ({ transform }) => {
      const svgGroup = d3.select(svgGroupRef.current);
      svgGroup.attr("transform", transform);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [svgGroupRef.current]
  );

  const zoom = d3.zoom<SVGSVGElement, unknown>().scaleExtent([0.1, 4]).on("zoom", zoomed);

  useEffect(() => {
    if (d3Ref.current && svgGroupRef.current) {
      const svgEl: d3.Selection<SVGSVGElement, unknown, null, undefined> = d3.select(d3Ref.current);
      if (orientation === "horizontal") {
        svgEl.attr("class", "overlay").call(zoom);
      } else {
        svgEl.on(".zoom", null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d3Ref.current, svgGroupRef.current, orientation]);

  const centerNode = useCallback(
    (source: d3.HierarchyPointNode<HealthNode>) => {
      if (d3Ref.current && orientation !== "vertical") {
        const _svg = d3.select(d3Ref.current);
        _svg
          .transition()
          .duration(750)
          .call(
            zoom.transform,
            d3.zoomIdentity
              .translate(width / 2, height / 2)
              .scale(1)
              .translate(-source.y, -source.x)
          );
      }
    },
    [zoom, height, width, orientation]
  );

  const onNodeClick = async (node: d3.HierarchyPointNode<HealthNode>): Promise<void> => {
    removeWarning("monitorId");
    if (node.children) {
      CacheHelper.resetNode(node.data.id);
      node.data.children = [];
    } else if (CacheHelper.has(node.data.id)) {
      node.data.children = CacheHelper.loadMore(node.data.id);
    } else if (
      (node.data.hasChildren && !node.children && !CacheHelper.has(node.data.id)) ||
      (node.data.hasResourceId && !CacheHelper.has(node.data.id))
    ) {
      const response = await loadNodeData(node.data);
      node.data.children = CacheHelper.addNode(node.data.id, response.children);
    }
    try {
      onNodeClicked(node.data);
    } catch (e) {
      setWarnings((prevWarning) => ({
        ...prevWarning,
        monitorId: {
          order: 0,
          message: (e as Error).message,
        },
      }));
    }
    setSelectedNode(node);
    trackEvent("NodeClicked", { depth: node.depth.toString() });
  };

  const onLoadMore = (parent: d3.HierarchyPointNode<HealthNode>) => {
    parent.data.children = CacheHelper.loadMore(parent.data.id);
    setSelectedNodeById(_.last(parent.data.children)?.id);
    trackEvent("LoadMoreNodeClicked");
  };

  const showMenu = (node: HealthNode, point: Point) => {
    showContextMenu(node, point);
    trackEvent("NodeRightClicked");
  };

  const styles = getStyles();
  const divStyle = orientation === "vertical" ? styles.vertical : styles.horizontal;

  const displayWarningsSection = useMemo(() => {
    return <Warnings warnings={warnings} removeWarning={removeWarning} />;
  }, [removeWarning, warnings]);

  return (
    <>
      {displayWarningsSection}
      <div ref={chartDiv} className={divStyle} style={{ width, height }} onClick={hideContextMenu}>
        <svg ref={d3Ref} height={treeHeight} width="100%">
          <g ref={svgGroupRef} transform={getRootGroupTransform(orientation)}>
            {links.map((link) =>
              orientation === "vertical" ? (
                <VerticalLink source={link.source} target={link.target}></VerticalLink>
              ) : (
                <Link
                  source={[link.source.y, link.source.x]}
                  target={[link.target.y, link.target.x]}
                  data={link.target.data}
                ></Link>
              )
            )}
            {nodes.map((node) => (
              <Node
                id={_.uniqueId(node.data.id) || node.data.id}
                node={node}
                x={node.x}
                y={node.y}
                orientation={orientation}
                isRightClicked={rightClickedNode.id === node.data.id}
                isSelected={selectedNode?.data.id === node.data.id}
                onNodeClick={onNodeClick}
                showContextMenu={showMenu}
              ></Node>
            ))}
            {loadMoreNodes.map((node, index) => (
              <LoadMoreNode
                id={index}
                parent={node.parent}
                x={node.x}
                y={node.y}
                display={CacheHelper.getLoadMoreString(node.data.parentId)}
                onNodeClick={onLoadMore}
              ></LoadMoreNode>
            ))}
          </g>
        </svg>
      </div>
    </>
  );
};
