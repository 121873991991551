import { DataSourceInstanceSettings, PanelData, PanelProps } from "@grafana/data";
import React, { useMemo } from "react";
import { TreeView } from "./TreeView";
import { HealthPanelProps } from "./types";
import { healthPanel } from "constants/appInsightEvents";
import { QueryClientProvider } from "react-query";
import { ProvideDataSourceInstanceSettings } from "stores/DataSourceInstanceSettingsStore";
import { queryClient } from "api/queryClient";
import { GenevaJsonData } from "types";
import { useUnhandledErrorReporter } from "util/useUnhandledErrorReporter";
import { ErrorBoundary } from "components/ErrorBoundary";
import { trackPageView } from "appInsights";

type Props = PanelProps<HealthPanelProps>;

const HealthPanel = (props: Props) => {
  const data = props.data;

  const warn = useMemo(() => {
    if (!data || !data.series.length) {
      return "No data found in response";
    } else if (data.series.length > 1 || data.series[0].name !== "nodes") {
      return "Data does not have a nodes field";
    } else if (!getDataSourceInstanceSettings(data)) {
      return "Geneva data source instance settings not found";
    } else if (data.series[0].fields.length === 0 || !data.series[0].fields.some((f) => f.name === "parentId")) {
      return "Data does not have a parentId field";
    } else {
      return null;
    }
  }, [data]);

  if (warn) {
    trackPageView({
      name: healthPanel,
      properties: { warningMessage: warn, data: data.series },
    });

    return (
      <div className="panel-empty">
        <p>{warn}</p>
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ProvideDataSourceInstanceSettings
        // TS doesn't follow this flow but data source instance settings is definitely
        // defined here. We will render a warning message above if it is not.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        dataSourceInstanceSettings={getDataSourceInstanceSettings(data)!}
      >
        <TreeView {...props} />
      </ProvideDataSourceInstanceSettings>
    </QueryClientProvider>
  );
};

export default (props: Props) => {
  useUnhandledErrorReporter("HealthPanel");

  return (
    <ErrorBoundary>
      <HealthPanel {...props} />
    </ErrorBoundary>
  );
};

function getDataSourceInstanceSettings(data: PanelData): DataSourceInstanceSettings<GenevaJsonData> | undefined {
  return data.series[0]?.meta?.custom?.dataSourceInstanceSettings;
}
