import { useCallback, useState } from "react";

export interface Warning {
  // order of the warning message to show on the UI
  order?: number;
  message: string;
  // track if this warning should be of severity "info"
  isInfo?: boolean;
}

/**
 * Stores a record of all the warnings in a component
 * @returns
 */
export function useWarning() {
  const [warnings, setWarnings] = useState<Record<string, Warning>>({});

  const removeWarning = useCallback((key: string) => {
    setWarnings((prevWarning) => {
      delete prevWarning[`${key}`];
      // need to set a new object this, for some reason just deleting keys,
      // and setting warnings, don't trigger rerender
      return {
        ...prevWarning,
      };
    });
  }, []);

  return { warnings, setWarnings, removeWarning };
}
