import { HealthNode } from "panel/types";
import React, { createContext, useCallback, useContext, useRef, useState } from "react";

interface TreeState {
  rightClickedNodeRefresh: HealthNode;
  updateSuppressionNode: HealthNode;
}

const context = createContext<{
  state: TreeState;
  getState: () => TreeState;
  setState: React.Dispatch<React.SetStateAction<TreeState>>;
} | null>(null);

export const TreeStore = {
  useState: () => {
    const store = useContext(context);

    if (store === null) {
      throw new Error("Provide tree store settings.");
    }

    return store;
  },
  Provide: ({ initialState, children }: { children: React.ReactNode; initialState: TreeState }) => {
    const [state, setState] = useState(initialState);
    const stateRef = useRef(state);
    stateRef.current = state;
    const getState = useCallback(() => stateRef.current, []);

    return (
      <context.Provider
        value={{
          state,
          setState,
          getState,
        }}
      >
        {children}
      </context.Provider>
    );
  },
};
