import { PanelPlugin, SelectableValue } from "@grafana/data";
import { getTemplateSrv } from "@grafana/runtime";
import HealthPanel from "./HealthPanel";
import { HealthPanelProps } from "./types";

// This export is used by Grafana itself and so
// needs to be kept around even though it isn't
// used within our codebase.
// eslint-disable-next-line import/no-unused-modules
export const plugin = new PanelPlugin<HealthPanelProps>(HealthPanel).setPanelOptions((builder) => {
  const variableOptions = getTemplateSrv()
    .getVariables()
    .map((v) => ({
      label: `$${v.name}`,
      value: `$${v.name}`,
      key: `${v.name}`,
    }));
  const varSelectOptions: SelectableValue<string>[] = [{ label: "Template Variables", options: variableOptions }];
  return builder
    .addSelect({
      path: "orientation",
      name: "Set the orientation for topology layout.",
      settings: {
        options: [
          { label: "Horizontal", value: "horizontal" },
          { label: "Vertical", value: "vertical" },
        ],
      },
      defaultValue: "horizontal",
    })
    .addSelect({
      path: "resourceHealthVar",
      name: "Variable to update with selected resource id.",
      settings: {
        options: varSelectOptions,
      },
    })
    .addSelect({
      path: "resourceNameVar",
      name: "Variable to update with selected resource name.",
      settings: {
        options: varSelectOptions,
      },
    })
    .addSelect({
      path: "monitorVar",
      name: "Variable to update with selected monitor.",
      settings: {
        options: varSelectOptions,
      },
    })
    .addSelect({
      path: "monitorNameVar",
      name: "Variable to update with selected monitor name.",
      settings: {
        options: varSelectOptions,
      },
    });
});
