import { Button } from "@grafana/ui";
import React from "react";
import { css } from "@emotion/css";
import { HealthNode } from "panel/types";

interface LoadMoreProps {
  id: number;
  x: number;
  y: number;
  parent: d3.HierarchyPointNode<HealthNode> | null;
  display: string;
  onNodeClick: (parent: d3.HierarchyPointNode<HealthNode>) => void;
}

const getStyles = () => ({
  loadMoreNode: css({
    display: "inline-block",
    cursor: "pointer",
    height: "100%",
  }),
  loadButton: css({
    padding: 5,
  }),
});

export const LoadMoreNode = ({ id, x, y, parent, display, onNodeClick }: LoadMoreProps) => {
  const styles = getStyles();
  const onClick = () => {
    if (parent) {
      onNodeClick(parent);
    }
  };
  return (
    <g key={id} transform={`translate(${y}, ${x})`}>
      <foreignObject key={id} height="35px" width="100%" x={0} y={-12.5}>
        <div className={styles.loadMoreNode}>
          <Button className={styles.loadButton} size="md" variant="secondary" onClick={onClick}>
            {display}
          </Button>
        </div>
      </foreignObject>
    </g>
  );
};
