import { useTheme2 } from "@grafana/ui";
import * as React from "react";

export const Folder = () => {
  const theme = useTheme2();
  const fill = theme.colors.text.primary;
  return (
    <svg width="10px" height="10px" viewBox="0 0 9 7" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.71875 1.40625V5.90625C8.71875 5.98242 8.69092 6.04834 8.63525 6.104C8.57959 6.15967 8.51367 6.1875 8.4375 6.1875H0.5625C0.486328 6.1875 0.42041 6.15967 0.364746 6.104C0.309082 6.04834 0.28125 5.98242 0.28125 5.90625V0.28125C0.28125 0.205078 0.309082 0.13916 0.364746 0.0834961C0.42041 0.027832 0.486328 0 0.5625 0H3.79688C3.91699 0 4.0166 0.027832 4.0957 0.0834961C4.1748 0.13916 4.24658 0.208008 4.31104 0.290039C4.37549 0.37207 4.43408 0.462891 4.48682 0.5625C4.53955 0.662109 4.60547 0.751465 4.68457 0.830566C4.76367 0.909668 4.85303 0.97998 4.95264 1.0415C5.05225 1.10303 5.18262 1.13086 5.34375 1.125H8.4375C8.51367 1.125 8.57959 1.15283 8.63525 1.2085C8.69092 1.26416 8.71875 1.33008 8.71875 1.40625Z"
        fill={fill}
      />
    </svg>
  );
};
