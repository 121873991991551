import { trackException } from "appInsights";
import { useEffect } from "react";

export function useUnhandledErrorReporter(root: string) {
  return useEffect(() => {
    window.addEventListener("error", reportError);
    window.addEventListener("unhandledrejection", reportUnhandledRejection);

    function reportError(event: ErrorEvent) {
      trackException({
        exception: event.error,
        properties: {
          root,
          reporter: "errorListener",
          colno: event.colno,
          lineno: event.lineno,
          filename: event.filename,
          message: event.message,
        },
      });
    }

    function reportUnhandledRejection(event: PromiseRejectionEvent) {
      trackException({
        exception: event.reason,
        properties: {
          root,
          reporter: "unhandledrejectionListener",
        },
      });
    }

    return () => {
      window.removeEventListener("error", reportError);
      window.removeEventListener("unhandledrejection", reportUnhandledRejection);
    };
  }, [root]);
}
