import { DataSourceInstanceSettings } from "@grafana/data";
import { GenevaJsonData } from "types";
import { HealthNode } from "../types";
import { loadMonitors, loadResources } from "./apiShim";

export const loadNodeData = async (
  node: HealthNode,
  account: string,
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>,
  stamp?: string
): Promise<HealthNode> => {
  const promises: Promise<HealthNode | HealthNode[]>[] = [];
  let returnNode = node;
  if (node.hasChildren) {
    promises.push(loadResources(node, account, dataSourceInstanceSettings, stamp));
  }
  if (node.hasResourceId) {
    promises.push(loadMonitors(node, account, dataSourceInstanceSettings, stamp));
  }
  if (promises.length > 0) {
    const responses = await Promise.all(promises);
    if (responses.length === 1) {
      if (responses[0] && !Array.isArray(responses[0])) {
        returnNode = responses[0];
      } else if (responses[0] && Array.isArray(responses[0])) {
        let monitorChildren: HealthNode[] = [];
        if (responses[0] && responses[0].length === 1) {
          // All monitors are in the "Default folder"
          monitorChildren = responses[0][0].children;
        } else {
          monitorChildren = responses[0];
        }
        for (const m of monitorChildren) {
          m.parentId = returnNode.id;
          m.parent = returnNode;
        }
        returnNode.children = [...monitorChildren, ...returnNode.children];
      }
    } else {
      if (responses[0] && !Array.isArray(responses[0])) {
        returnNode = responses[0];
      }
      if (responses[1] && Array.isArray(responses[1])) {
        for (const m of responses[1]) {
          m.parentId = returnNode.id;
          m.parent = returnNode;
        }
        returnNode.children = [...responses[1], ...returnNode.children];
      }
    }
  }
  return returnNode;
};
