import React, { useEffect, useRef, useState } from "react";
import { DataFrame, PanelProps, UrlQueryMap } from "@grafana/data";
import { HealthNode, HealthPanelProps, Point } from "./types";
import { getLocationSrv, LocationSrv } from "@grafana/runtime";
import _ from "lodash";
import { treeView } from "constants/appInsightEvents";
import { Tree } from "./Tree";
import { useDataSourceInstanceSettings } from "stores/DataSourceInstanceSettingsStore";
import { NodeContextMenuView } from "./NodeContextMenu";
import { trackPageView, trackEvent as aiTrackEvent, trackException } from "appInsights";
import { convertNodeFrameToTree } from "./utils/healthNodeUtils";
import { loadNodeData } from "./utils/fetchDataUtils";
import { TreeStore } from "./stores/TreeStore";
import { getMonitorDisplayName, getMonitorId } from "util/monitorUtils";
import { MonitorVar } from "types";
import * as CacheHelper from "./Tree/CacheHelper";
import { LoadingPlaceholder } from "@grafana/ui";
import { stringify } from "util/stringify";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

type Props = PanelProps<HealthPanelProps>;

export const TreeView: React.FC<Props> = ({ data, width, height, options, replaceVariables }: Props) => {
  const [environment, setEnvironment] = useState<string>();
  const [account, setAccount] = useState<string>();
  const [stamp, setStamp] = useState<string>();
  const [selectedResource, setSelectedResource] = useState<string>();
  const [root, setRoot] = useState<HealthNode>({} as HealthNode);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [menuNode, setMenuNode] = useState<HealthNode>({} as HealthNode);
  const [menuPoint, setMenuPoint] = useState<Point>({} as Point);
  const [selectedNodeId, setSelectedNodeId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dataSourceInstanceSettings = useDataSourceInstanceSettings();

  const onNodeClicked = (node: HealthNode) => {
    if (node.type === "folder") {
      let parent = node.parent;
      while (parent && !parent?.hasResourceId) {
        parent = parent.parent;
      }
      if (parent) updateVariables(parent);
    } else if (node.type === "monitor") {
      const monitorDisplayName = getMonitorDisplayName({
        ...node,
        metadata: node.monitorMetadata,
        healthy: false,
        message: "",
        monitorType: 0,
        timeStamp: "",
        preAggThrottlingFraction: 0,
      });
      let parent = node.parent;
      while (parent && !parent?.hasResourceId) {
        parent = parent.parent;
      }
      if (parent) updateVariables(parent, getMonitorId(node.monitorMetadata), monitorDisplayName);
    } else if (node.hasResourceId) {
      updateVariables(node);
      clearMonitorVariables();
    } else {
      clearVariables();
    }
  };

  const updateVariables = (node: HealthNode, monitorId?: string, monitorName?: string) => {
    const locationSrv = getLocationSrv();
    updateResourcesVar(node.id, locationSrv);
    updateResourceNameVar(node.name, locationSrv);
    updateMonitorVar(node, monitorId, monitorName);
  };

  const updateResourcesVar = (id: string, locationSrv: LocationSrv) => {
    replaceVar(options.resourceHealthVar, id, locationSrv);
  };

  const updateResourceNameVar = (name: string, locationSrv: LocationSrv) => {
    replaceVar(options.resourceNameVar, name, locationSrv);
  };

  const updateMonitorVar = (node: HealthNode, monitorId?: string, monitorName?: string) => {
    if (monitorId) {
      const locationSrv = getLocationSrv();
      const monitor: MonitorVar = {
        monitorId,
        resourceType: node.resourceType,
        resourceName: node.resourceName,
      };
      replaceVar(options.monitorVar, JSON.stringify(monitor), locationSrv);
      replaceVar(options.monitorNameVar, monitorName ?? "", locationSrv);
    }
  };

  const clearMonitorVariables = () => {
    const locationSrv = getLocationSrv();
    replaceVar(options.monitorVar, "", locationSrv);
    replaceVar(options.monitorNameVar, "", locationSrv);
  };

  const clearVariables = () => {
    const locationSrv = getLocationSrv();
    replaceVar(options.resourceHealthVar, "", locationSrv);
    replaceVar(options.resourceNameVar, "", locationSrv);
    replaceVar(options.monitorVar, "", locationSrv);
    replaceVar(options.monitorNameVar, "", locationSrv);
  };

  const trackEvent = (name: string, properties?: { [key: string]: string }) => {
    aiTrackEvent({
      name,
      properties: {
        account: account,
        stamp,
        environment: environment,
        properties,
      },
    });
  };

  const replaceVar = (name: string, value: string, locationSrv: LocationSrv) => {
    if (name) {
      const varFormat = `var-${name.slice(1)}`;
      const query: UrlQueryMap = {};
      query[`${varFormat}`] = value;
      locationSrv.update({
        query,
        partial: true,
        replace: true,
      });
    }
  };

  const updateSelectedNode = () => {
    const selectedResourceId = replaceVariables(options.resourceHealthVar);
    let selectedMonitorId = "";
    const selectedMonitor = replaceVariables(options.monitorVar);
    if (selectedMonitor) {
      try {
        const monitorVar: MonitorVar = JSON.parse(selectedMonitor);
        if (monitorVar) {
          selectedMonitorId = monitorVar.monitorId;
        }
      } catch (error) {
        trackEvent("failed to parse monitor variable", {
          monitorVar: selectedMonitor,
        });
      }
    }
    setSelectedNodeId(selectedMonitorId || selectedResourceId);
  };

  const firstUpdate = useRef(true);

  const setQueryParams = (frame: DataFrame) => {
    const entries = Object.entries(frame?.meta?.custom || {}) as [string, string][];
    for (const [key, val] of entries) {
      if (key === "Geneva_Environment" && environment !== val) {
        setEnvironment(val);
      } else if (key === "Geneva_Account" && account !== val) {
        setAccount(val);
      } else if (key === "Geneva_Health_Resource" && selectedResource !== val) {
        setSelectedResource(val);
      } else if (key === "Geneva_Stamp" && stamp !== val) {
        setStamp(val);
      }
    }
  };
  useEffect(() => {
    setQueryParams(data.series[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const setTopologyRoot = async () => {
      const initialRoot = convertNodeFrameToTree(data.series[0]);
      const expandedPath = replaceVariables(options.resourceHealthVar);
      if (expandedPath) {
        setIsLoading(true);
        const ids = expandedPath.split("$");
        let curr = initialRoot;
        if (ids && ids.length > 0) {
          for (let i = 2; i <= ids.length; i++) {
            const slice = ids.slice(0, i);
            const id = slice.join("$");
            const child = curr.children.find((c) => c.id.toLowerCase() === id.toLowerCase());
            if (child) {
              try {
                const response = await loadNodeData(child, account || "", dataSourceInstanceSettings, stamp);
                child.children = CacheHelper.addNode(child.id, response.children, true);
                curr = child;
              } catch (e) {
                trackException({
                  exception: e instanceof Error ? e : new Error(stringify(e)),
                  severityLevel: SeverityLevel.Error,
                  properties: {
                    reporter: "TreeView.setTopologyRoot",
                    account,
                    stamp,
                    id: child.id,
                  },
                });
                // At this point we have failed to load a node.
                // No point in fetching the descendants.
                // Break out of the for loop and set the root to what we have till now.
                break;
              }
            }
          }
        }
      }
      setRoot(initialRoot);
      setIsLoading(false);
    };
    if (account && selectedResource) {
      CacheHelper.clearCache();
      setTopologyRoot();
      if (firstUpdate.current) {
        updateSelectedNode();
        firstUpdate.current = false;
      } else {
        clearVariables();
      }
      trackPageView({
        name: treeView,
        properties: {
          environment,
          account,
          resource: selectedResource,
        },
      });
    }
    // TODO fix hook dependencies here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, selectedResource]);

  const showContextMenu = (node: HealthNode, point: Point) => {
    setMenuPoint(point);
    setMenuNode(node);
    setShowMenu(true);
  };

  const hideContextMenu = () => {
    setShowMenu(false);
  };

  return (
    <TreeStore.Provide
      initialState={{
        rightClickedNodeRefresh: {} as HealthNode,
        updateSuppressionNode: {} as HealthNode,
      }}
    >
      {isLoading && <LoadingPlaceholder text="Loading..." />}
      {!isLoading && (
        <>
          <Tree
            width={width}
            height={height}
            data={root}
            account={account || ""}
            selectNodeByVars={selectedNodeId || ""}
            loadNodeData={(node) => {
              return loadNodeData(node, account ?? "", dataSourceInstanceSettings, stamp);
            }}
            onNodeClicked={onNodeClicked}
            orientation={options.orientation}
            showContextMenu={showContextMenu}
            hideContextMenu={hideContextMenu}
            trackEvent={trackEvent}
          />
          <NodeContextMenuView
            show={showMenu}
            point={menuPoint}
            node={menuNode}
            account={account || ""}
            dataSourceSettings={dataSourceInstanceSettings}
            onClose={hideContextMenu}
          ></NodeContextMenuView>
        </>
      )}
    </TreeStore.Provide>
  );
};
