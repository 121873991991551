import _ from "lodash";
import { CustomLinkConfiguration } from "types";
import { encodeParams } from "../customLinkUtils";

interface EventFilterWrapper {
  namespaceRegex: string;
  nameRegex: string;
  versionRegex?: string;
  monikerRegex?: string;
  isScrubbed?: boolean;
}

interface IEventInfoWrapper {
  namespace: string;
  name: string;
  moniker: string;
  region: string;
  version: string;
  isScrubbed: boolean;
}

interface ISimpleQueryConditions {
  comparand: string;
  operator: string;
  values: string;
}

interface DGrepLogsLinkConfiguration extends CustomLinkConfiguration {
  eventFilters: EventFilterWrapper[];
  eventInfos: IEventInfoWrapper[];
  endpointAlias: string;
  mdsEndpoint: string;
  dGrepEndpoint: string;
  identityColumns: { [key: string]: string[] };
  serverQuerySimpleConditions: ISimpleQueryConditions[];
  stepback: string;
  lookback: string;
  startTime: string;
  endTime: string;
  serverQuery: string;
  clientQuery: string;
  maxRowCount: number;
  maxResultRowCount: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uxParameters: { [key: string]: any }[];
  cacheQueryMode?: string;
  useAdvancedQuery?: string | boolean;
  jarvisPathAndQuery?: string;
  addedValueHints?: string[];
}

/**
 * Plain js condition, used for saving conditions
 *
 * Note that parts MUST NOT be URL encoded
 */
interface Condition {
  /**
   * The name of the comparand that is being compared against
   */
  comparand: string;

  /**
   * The chosen operation to use for the comparison
   */
  operator: string;

  /**
   * The value(s) to compare against the comparand
   */
  values: string;
}

/**
 * Chart Layer for saving as plain js.
 */
interface ChartLayer {
  /**
   * The name of the layer.
   */
  name: string;

  /**
   * Flag for chart is expanded or collapsed.
   */
  expanded: boolean;

  /**
   * Chart query
   */
  chartQuery: string;

  // OLD members required for converting old charts to new chart
  /**
   * Legends for chart
   */
  legends?: string[];

  /**
   * Aggregation in chart
   */
  aggregation?: string;

  /**
   * The aggregation period.
   */
  aggregationPeriod?: number;

  /**
   * The aggregation period unit.
   */
  aggregationPeriodUnit?: string;

  /**
   * The where conditions.
   */
  whereConditions?: Condition[];
}

interface Aggregate {
  /**
   * The aggregate type.
   */
  aggregateType: string;

  /**
   * The aggregate of column.
   */
  aggregateOfColumn?: string;

  /**
   * The aggregate by column.
   */
  aggregateByColumn: string;
}

type QueryParams = {};

interface DGrepQueryParams extends QueryParams {
  page?: string;
  be?: string;
  /**
   * The endpoint.
   * ex: ep=Test
   */
  ep: string;
  /**
   * The namespace.
   * ex: ns:Aims
   */
  ns: string;
  /**
   * The event names.
   * ex: en=LogEvent,MaCounterSummary
   */
  en: string;
  /**
   * The reference time.
   * ex: time=04/13/2015 11:35
   */
  time?: string;
  /**
   * The offset.
   * ex: offset=-30
   */
  offset?: string;
  /**
   * The offset unit.
   * ex: offsetUnit=Mins || offsetUnit=hours
   */
  offsetUnit?: string;
  /**
   * UTC or local.
   * ex: UTC=true
   */
  UTC?: string;
  /**
   * Date from.
   * ex: dateFrom=04/13/2015 11:35
   */
  dateFrom?: string;

  /**
   * Date to.
   * ex: dateTo=04/13/2015 12:35
   */
  dateTo?: string;

  /**
   * The scale unit.
   * ex: su=MdsBvt1,MdsBvt2
   */
  su?: string;

  /**
   * The Role.
   * ex: role=Aims1,Aims2
   */
  role?: string;

  /**
   * The Role instance.
   * ex: role=ri1,ri2
   */
  roleInstance?: string;

  /**
   * The Event version.
   * ex: ver=3v0
   */
  ver?: string;

  /**
   * The scoping condition.
   * ex: scopingConditions=[["Role","PROD-BY-001","RoleInstance","AIMS-00-001"]]
   */
  scopingConditions?: string;

  /**
   * The simple condition.
   * ex: conditions=[["Tenant","%3D%3D","a"],["ActivityId","contains%20any%20of","a,b,c"]]
   */
  conditions?: string;

  /**
   * if aggregates are visible
   * ex: aggregatesVisible=true
   */
  aggregatesVisible?: string;

  /**
   * The Aggregates
   * ex: aggregates=["Average of Level by Role","Count by Level"]
   */
  aggregates?: string;

  /**
   * The server query.
   * ex: serverQuery=where level%3D%3D2
   */
  serverQuery?: string;

  /**
   * The server query type:
   * * If serverQuery is not specified, it is simple.
   * * otherwise:
   *   * If unspecified (legacy) or "mql", it's MQL
   *   * If "kql", it's KQL
   *   * otherwise undefined behavior.
   */
  serverQueryType?: string;

  /**
   * The client query.
   * ex: clientQuery=where level%3D%3D2
   */
  clientQuery?: string;

  /**
   * The client query if kql is being used instead of mql.
   * ex: clientQuery=source
   */
  kqlClientQuery?: string;

  /**
   * Whether or not charts in dgrep are visible
   */
  chartsVisible?: string;

  /**
   * Whether or not chart editor is visible
   */
  chartEditorVisible?: string;

  /**
   * The chart type.
   */
  chartType?: string;

  /**
   * Chart layers
   * ex: chartLayers=[["Layer1", "<chart Query>", "Line"]]
   */
  chartLayers?: string;
}

const startTimeTemplate = "{StartTime}";
const endTimeTemplate = "{EndTime}";

const aggregatesFormatExpression = /^(count|(sum|avg|average|min|max) of ([^\s]+)) by ([^\s]+)$/i;

const dgrepUxParamList = {
  aggregatesVisible: "aggregatesVisible",
  aggregates: "aggregates",
  chartsVisible: "chartsVisible",
  chartEditorVisible: "chartEditorVisible",
  chartType: "chartType",
  chartLayers: "chartLayers",
  UTC: "UTC",
};

type OffsetUnitNames = "Minutes" | "Hours" | "Days";

interface TimeOffset {
  offset: number;
  offsetUnit: OffsetUnitNames;
}

const splitTimespan = (timespan: string): [number, number, number, number] => {
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  // remove +/-/~ prefix if found
  if (timespan[0].match(/[+\-~]/)) {
    timespan = timespan.slice(1);
  }

  const parts = timespan.split(":");

  if (parts.length >= 2) {
    const daysAndHours = parts[0].split(".");
    if (daysAndHours.length === 2) {
      days = +daysAndHours[0] || 0;
      hours = +daysAndHours[1] || 0;
    } else {
      hours = +daysAndHours[0] || 0;
    }

    minutes = +parts[1];
    seconds = +parts[2];
  }

  return [days, hours, minutes, seconds];
};

const convertTimespanToOffsetAndOffsetUnit = (timespan: string): TimeOffset => {
  const [days, hours, minutes] = splitTimespan(timespan);

  if (hours && days) {
    // Hours and days both exist then change full thing to hours
    return {
      offset: hours + days * 24,
      offsetUnit: "Hours",
    };
  }

  if (days) {
    return {
      offset: days,
      offsetUnit: "Days",
    };
  }

  if (hours) {
    // Hours
    return {
      offset: hours,
      offsetUnit: "Hours",
    };
  }

  if (minutes) {
    // Minutes
    return {
      offset: minutes,
      offsetUnit: "Minutes",
    };
  }

  // Most probably this is in seconds, but in UI we can only show minutes.
  return {
    offset: 1, // round up to minutes, or we will end up with no data
    offsetUnit: "Minutes",
  };
};

const multiplyTimespan = (timespan: string, multiplier: number): string => {
  let [days, hours, minutes] = splitTimespan(timespan);

  days *= multiplier;
  hours *= multiplier;
  minutes *= multiplier;

  const totalMinutes = days * 24 * 60 + hours * 60 + minutes;

  days = Math.floor(totalMinutes / (24 * 60));
  hours = Math.floor((totalMinutes - days * 24 * 60) / 60);
  minutes = totalMinutes - days * 24 * 60 - hours * 60;
  return `${days}.${hours}:${minutes}:00`;
};

const parseAggregates = (aggregates: string[]): Aggregate[] => {
  const result: Aggregate[] = [];

  for (let n = 0; n < aggregates.length; ++n) {
    // n is a number, no user input
    // eslint-disable-next-line security/detect-object-injection
    const aggString = aggregates[n];
    // Parse aggregates
    if (aggString) {
      const tokens = aggregatesFormatExpression.exec(aggString);
      if (!tokens) {
        continue;
      }
      if (tokens[3]) {
        result.push({
          aggregateType: tokens[2],
          aggregateOfColumn: tokens[3],
          aggregateByColumn: tokens[4],
        });
      } else {
        result.push({
          aggregateType: tokens[1],
          aggregateByColumn: tokens[4],
        });
      }
    }
  }

  return result;
};

const generateDGrepDeepLink = (config: Partial<DGrepLogsLinkConfiguration>, referenceDateTime?: Date) => {
  const link = {
    activity: "dgrep",
    version: 2,
    endpoint: config.endpointAlias || "Diagnostics PROD",
    referenceDate: (referenceDateTime && referenceDateTime.toISOString()) || undefined,
    chartLayers: [],
    offset: 30,
    offsetSign: "-",
    offsetUnit: "Minutes",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;

  link.namespaces = _.uniq((config.eventFilters || []).map((filter) => filter.namespaceRegex));
  link.eventNames = _.uniq((config.eventFilters || []).map((filter) => filter.nameRegex));

  const lookback = config.lookback;

  if (config.startTime === startTimeTemplate && config.endTime === endTimeTemplate) {
    // OLD link, make offset to 5 minutes.
    link.offsetSign = "~";
    link.offset = 5;
    link.offsetUnit = "Minutes";
  } else if (lookback) {
    const stepback = config.stepback;
    let offsetItems: TimeOffset;
    const isStepbackZero = stepback && splitTimespan(stepback).every((part) => part === 0);
    if (stepback && !isStepbackZero) {
      // + or ~
      if (stepback[0] === "-") {
        // UI will always generate config with (-) step back
        const stepbackTimespan = stepback.slice(1);
        const lookbacktimespan = multiplyTimespan(lookback, 1);
        const doubleStepback = multiplyTimespan(stepbackTimespan, 2);
        if (doubleStepback === lookbacktimespan) {
          // ~
          link.offsetSign = "~";
        } else {
          // +
          link.offsetSign = "+";
        }

        offsetItems = convertTimespanToOffsetAndOffsetUnit(stepbackTimespan);
        link.offset = offsetItems.offset;
        link.offsetUnit = offsetItems.offsetUnit;
      } else {
        // Non UI generated config, ignore time range for now
        // If we come up with some strategy to convet it then implement that here.
      }
    } else {
      // - scenario
      link.offsetSign = "-";

      offsetItems = convertTimespanToOffsetAndOffsetUnit(lookback);
      link.offset = offsetItems.offset;
      link.offsetUnit = offsetItems.offsetUnit;
    }
  }

  // Scoping conditions
  const identityConditions = config.identityColumns;
  if (identityConditions) {
    link.scopingConditions = [];
    for (const key in identityConditions) {
      if (Object.prototype.hasOwnProperty.call(identityConditions, key)) {
        const values = identityConditions[`${key}`];
        link.scopingConditions.push({
          comparand: key,
          operator: "=",
          values: values.join(","),
        });
      }
    }
  }

  // Server query
  const serverQueryConditions = config.serverQuerySimpleConditions;
  link.useAdvancedQuery = !serverQueryConditions || serverQueryConditions.length < 1;
  if (link.useAdvancedQuery) {
    link.advancedQuery = config.serverQuery || "";
    link.useAdvancedQuery = config.useAdvancedQuery || true;
  } else {
    link.simpleQueryConditions = serverQueryConditions;
  }

  // Client query
  link.cacheQuery = config.clientQuery || "";
  link.cacheQueryMode = config.cacheQueryMode || undefined;

  // Max rows in result
  if (config.maxRowCount) {
    link.maxResults = config.maxRowCount;
  }

  const uxParams = config.uxParameters;
  // UX params
  if (uxParams) {
    uxParams.forEach((uxParam) => {
      switch (uxParam.key) {
        case dgrepUxParamList.aggregatesVisible:
          link.aggregatesVisible = !!uxParam.value;
          break;

        case dgrepUxParamList.aggregates:
          const aggregates = uxParam.value;
          if (aggregates && aggregates.length) {
            link.aggregates = parseAggregates(aggregates);
          }
          break;

        case dgrepUxParamList.chartsVisible:
          link.selectedTab = uxParam.value ? "Chart" : "Logs";
          break;

        case dgrepUxParamList.chartEditorVisible:
          link.chartEditorVisible = !!uxParam.value;
          break;

        case dgrepUxParamList.chartType:
          link.chartType = uxParam.value;
          break;

        case dgrepUxParamList.chartLayers:
          const chartLayers = uxParam.value;
          if (chartLayers && chartLayers.length) {
            chartLayers.forEach((layer: string[]) => {
              if (layer && layer.length >= 2) {
                link.chartLayers.push({
                  name: layer[0],
                  chartQuery: layer[1],
                  expanded: false,
                });
              }
            });
          }
          break;

        case dgrepUxParamList.UTC:
          link.isUtc = !!uxParam.value;
          break;

        default:
          break;
      }
    });
  }

  if (config.addedValueHints) {
    link.addedValueHints = config.addedValueHints;
  }

  return link;
};

const removeMarkupHackIdentityNames = (name: string): string => {
  switch (name) {
    case "<i>Moniker</i>":
      return "__Moniker__";

    case "<i>Region</i>":
      return "__Region__";

    case "<i>EventVersion</i>":
      return "__EventVersion__";

    default:
      return name;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const linkToQueryParams = (link: any, noUnusedParams = false) => {
  // page
  const params: DGrepQueryParams = {
    ...(!noUnusedParams && {
      page: "logs",
    }),
    be: "DGrep",
    ep: link.endpoint, // Endpoint
    ns: link.namespaces.join(","), // Namespaces
    en: link.eventNames.join(","), // Event names
  };

  if (link.referenceDate) {
    params.time = link.referenceDate;
  }

  if (link.isUtc) {
    params.UTC = `${link.isUtc}`;
  }

  if (link.offset) {
    params.offset = `${link.offsetSign}${link.offset}`;
  }

  if (link.offsetUnit) {
    params.offsetUnit = link.offsetUnit;
  }

  if (link.scopingConditions && link.scopingConditions.length) {
    const scopingConditionParamValue = link.scopingConditions.map((condition: Condition) => {
      return [condition.comparand, condition.values];
    });

    if (scopingConditionParamValue) {
      params.scopingConditions = JSON.stringify(scopingConditionParamValue);
    }
  }
  if (link.useAdvancedQuery) {
    // Advanced query
    params.serverQuery = link.advancedQuery;
    if (link.useAdvancedQuery === "kql") {
      params.serverQueryType = link.useAdvancedQuery;
    }
  } else {
    // simple conditions
    const conditionParamValue = link.simpleQueryConditions.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (condition: any) => {
        return [removeMarkupHackIdentityNames(condition.comparand), condition.operator, condition.values];
      }
    );
    if (conditionParamValue) {
      params.conditions = JSON.stringify(conditionParamValue);
    }
  }

  // client query
  if (link.cacheQuery) {
    if (link.cacheQueryMode === "KQL") {
      params.kqlClientQuery = link.cacheQuery;
    } else {
      params.clientQuery = link.cacheQuery;
    }
  }

  // Aggregates
  if (link.aggregatesVisible) {
    params.aggregatesVisible = "true";
  }

  if (link.aggregates && link.aggregates.length > 0) {
    const aggregateExpressions = link.aggregates
      // ensure they have required props
      .filter((aggregate: Aggregate) => !!aggregate.aggregateType && !!aggregate.aggregateByColumn)
      .map((aggregate: Aggregate) => {
        let aggregateString = aggregate.aggregateType;
        if (aggregate.aggregateType !== "Count") {
          aggregateString += " of " + aggregate.aggregateOfColumn;
        }

        return aggregateString + " by " + aggregate.aggregateByColumn;
      });

    params.aggregates = JSON.stringify(aggregateExpressions);
  }

  // Charts
  if (link.selectedTab === "Chart") {
    params.chartsVisible = "true";
  }

  if (link.chartEditorVisible) {
    params.chartEditorVisible = link.chartEditorVisible + "";
  }

  if (link.chartType) {
    params.chartType = link.chartType;
  }

  if (link.chartLayers && link.chartLayers.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const chartLayersParamValues: any[] = [];
    link.chartLayers.forEach((layer: ChartLayer) => {
      const layerInfo = [layer.name, layer.chartQuery];
      chartLayersParamValues.push(layerInfo);
    });

    params.chartLayers = JSON.stringify(chartLayersParamValues);
  }

  return params as QueryParams;
};

export const getDgrepLink = (config: CustomLinkConfiguration, monitorId: string) => {
  const dgrepConfig = config as DGrepLogsLinkConfiguration;
  const link = generateDGrepDeepLink(dgrepConfig);
  const queryParams = linkToQueryParams(link) as {
    [key: string]: string | number | boolean;
  };
  queryParams["monitor.id"] = monitorId;
  return `https://portal.microsoftgeneva.com/?${encodeParams(queryParams)}`;
};
