import { ContextMenuItem, HealthNode } from "panel/types";
import { CustomLinkConfiguration, LinkConfigurationType } from "types";
import { getMonitorId } from "util/monitorUtils";
import { getKustoLink } from "./customlinks/kusto";
import { getDashboardLink } from "./customlinks/dashboard";
import { getGrafanaLink } from "./customlinks/grafana";
import { getDgrepLink } from "./customlinks/dgrep";
import { getTraceLink } from "./customlinks/trace";

interface GenericCustomLinkConfiguration extends CustomLinkConfiguration {
  link: string;
}

export const encodeParams = (
  params: {
    [key: string]: string | number | boolean;
  },
  encode?: (uriComponent: string | number | boolean) => string
) => {
  if (!encode) {
    encode = encodeURIComponent;
  }
  const encodedParams: string[] = [];
  for (const key in params) {
    const encodedKey = encode(key);
    const encodedValue = encode(params[`${key}`]);
    encodedParams.push(`${encodedKey}=${encodedValue}`);
  }
  return encodedParams.join("&");
};

const getGenericLink = (config: CustomLinkConfiguration) => {
  return (config as GenericCustomLinkConfiguration).link;
};

export const getEnrichment = (config: CustomLinkConfiguration, node: HealthNode): ContextMenuItem | null => {
  switch (config.$type) {
    case LinkConfigurationType.Kusto:
      return {
        key: config.$type,
        icon: "search",
        label: config.title,
        url: getKustoLink(config, getMonitorId(node.monitorMetadata)),
        linkTarget: "_blank",
      };
    case LinkConfigurationType.Dashboard:
      return {
        key: config.$type,
        icon: "chart-line",
        label: config.title,
        url: getDashboardLink(config, node),
        linkTarget: "_blank",
      };
    case LinkConfigurationType.Grafana:
      return {
        key: config.$type,
        icon: "grafana",
        label: config.title,
        url: getGrafanaLink(config),
        linkTarget: "_blank",
      };
    case LinkConfigurationType.DGrep:
      return {
        key: config.$type,
        icon: "search",
        label: config.title,
        url: getDgrepLink(config, getMonitorId(node.monitorMetadata)),
        linkTarget: "_blank",
      };
    case LinkConfigurationType.Trace:
      return {
        key: config.$type,
        icon: "chart",
        label: config.title,
        url: getTraceLink(config),
        linkTarget: "_blank",
      };
    case LinkConfigurationType.Generic:
      return {
        key: config.$type,
        icon: "link",
        label: config.title,
        url: getGenericLink(config),
        linkTarget: "_blank",
      };
    default:
      return null;
  }
};
