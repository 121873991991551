import { MetaDataCollection } from "types";
import { LinkTarget } from "@grafana/data";

export interface HealthPanelProps {
  monitorNameVar: string;
  orientation: LayoutOrientation;
  resourceHealthVar: string;
  resourceNameVar: string;
  monitorVar: string;
}

type HealthNodeType = "node" | "loadMore" | "monitor" | "folder";

export type LayoutOrientation = "horizontal" | "vertical";

export enum SuppressionMode {
  Suppress,
  Unsuppress,
}

export enum SuppressionReason {
  Own = 0,
  Parent = 1,
  Unknown = 2,
}
export interface HealthNode {
  id: string;
  name: string;
  displayName: string;
  healthStatus: number;
  hasChildren: boolean;
  hasResourceId: boolean;
  children: HealthNode[];
  parentId: string;
  suppressionState: SuppressionMode;
  suppressionReason: SuppressionReason;
  resourceType: string;
  resourceName: string;
  monitorMetadata: MetaDataCollection;
  type?: HealthNodeType;
  preOrderIndex?: number; //stores the index of the node in pre-order traversal.
  parent?: HealthNode;
}

export interface Point {
  x: number;
  y: number;
}

export interface SuppressionRule {
  expiresAfter: string;
  rule: { [key: string]: string };
  mode: number;
  alias: string;
  creationTime: string;
  resourceTypes?: string[];
}

export interface ContextMenuItem {
  key: string;
  label: string;
  icon: string;
  url?: string;
  onClick?: (event?: React.SyntheticEvent<HTMLElement>) => void;
  getLabel?: (node?: HealthNode) => string;
  getIcon?: (node?: HealthNode) => string;
  getUrl?: (account: string, node?: HealthNode) => string;
  linkTarget?: LinkTarget;
}
