import { DataFrame, FieldCache, NodeGraphDataFrameFieldNames } from "@grafana/data";
import { HealthNode } from "panel/types";

const getNodeFields = (nodes: DataFrame) => {
  const fieldsCache = new FieldCache(nodes);
  return {
    id: fieldsCache.getFieldByName(NodeGraphDataFrameFieldNames.id),
    name: fieldsCache.getFieldByName("name"),
    displayName: fieldsCache.getFieldByName("displayName"),
    healthStatus: fieldsCache.getFieldByName("healthStatus"),
    hasChildren: fieldsCache.getFieldByName("hasChildren"),
    hasResourceId: fieldsCache.getFieldByName("hasResourceId"),
    resourceType: fieldsCache.getFieldByName("resourceType"),
    resourceName: fieldsCache.getFieldByName("resourceName"),
    suppressionState: fieldsCache.getFieldByName("suppressionState"),
    suppressionReason: fieldsCache.getFieldByName("suppressionReason"),
    parentId: fieldsCache.getFieldByName("parentId"),
    metadata: fieldsCache.getFieldByName("metadata"),
  };
};

//exported for testing purposes
export const reduceFrame = (frame: DataFrame, fromMonitors: boolean): HealthNode[] => {
  const nodeMap: Map<string, HealthNode> = new Map();
  const nodeFields = getNodeFields(frame);
  const nodes =
    nodeFields.id?.values.toArray().reduce<HealthNode[]>((acc, id, index) => {
      const node: HealthNode = {
        id: id,
        name: nodeFields.name?.values.get(index),
        displayName: nodeFields.displayName?.values.get(index),
        healthStatus: nodeFields.healthStatus?.values.get(index),
        hasChildren: nodeFields.hasChildren?.values.get(index),
        hasResourceId: nodeFields.hasResourceId?.values.get(index),
        resourceType: nodeFields.resourceType?.values.get(index),
        resourceName: nodeFields.resourceName?.values.get(index),
        suppressionState: nodeFields.suppressionState?.values.get(index),
        suppressionReason: nodeFields.suppressionReason?.values.get(index),
        parentId: nodeFields.parentId?.values.get(index),
        monitorMetadata: nodeFields.metadata?.values.get(index),
        children: [],
      };
      acc.push(node);
      nodeMap.set(node.id, node);
      if (fromMonitors) {
        node.type = node.parentId === null ? "folder" : "monitor";
      }
      return acc;
    }, []) || [];
  for (const node of nodes) {
    if (node.parentId) {
      const n = nodeMap.get(node.parentId);
      node.parent = n;
      if (n) {
        n.children = n.children.concat(node);
      }
    }
  }
  return nodes;
};

export const convertMonitorFrameToTree = (frame: DataFrame): HealthNode[] => {
  const folders: HealthNode[] = [];
  if (!frame) {
    return folders;
  }
  const nodes = reduceFrame(frame, true);
  for (const node of nodes) {
    if (node.type === "folder") {
      folders.push(node);
    }
  }
  return folders;
};

export const convertNodeFrameToTree = (frame: DataFrame): HealthNode => {
  let root: HealthNode = {} as HealthNode;
  if (!frame) {
    return root;
  }
  const nodes = reduceFrame(frame, false);
  root = nodes.find((n) => n.parentId === null) || root;
  return root;
};
