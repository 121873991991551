import { CustomLinkConfiguration } from "types";

interface TraceConfiguration extends CustomLinkConfiguration {
  /**
   *  Trace endpoind
   */
  endpoint: string;
  /**
   *  Trace namespace
   */
  namespace: string[];
  /**
   *  Trace time range, it could be of format of ["1h"] for relative time, ["2021-06-22T00:20:35.637Z", "2021-06-22T01:20:35.637Z"] for absolute time
   */
  time?: string[];
  /**
   *  Optional trace filters
   */
  state?: Array<Array<string | string[]>>;
  /**
   * Optional link that will be in the TraceConfiguration model
   */
  generatedLink?: string;
}

interface FilterSelection {
  field: string;
  operator: string;
  values: string[];
}

/**
 *  Sanitize filter value as needed. Currently we only decode region value
 *  Each item in the fitler array represents respectively filter name, filter operator and filter value
 *  such as [{filterName}, {filterOperator}, {filterValue}]
 *  filter value can be of type string or string[]
 */
const sanitizeFilterValue = (filter: Array<string | string[]>): string | string[] => {
  if (filter[0] === "Region") {
    // Decode region value
    filter[2] = (filter[2] as string[]).map(decodeURIComponent);
  }

  return filter[2];
};

const getFilterSelectionsFromArtifact = (artifact: TraceConfiguration): FilterSelection[] => {
  if (!artifact || !artifact?.state) {
    return [];
  }

  const filterSelections: FilterSelection[] = [
    {
      field: "StartTime",
      operator: "==",
      values: artifact.time || [],
    },
    {
      field: "Endpoint",
      operator: "==",
      values: [artifact.endpoint],
    },
    {
      field: "Namespace",
      operator: "==",
      values: artifact.namespace,
    },
  ];

  artifact.state.forEach((filter) => {
    filterSelections.push({
      field: filter[0],
      operator: decodeURIComponent((filter[1] as string) || "=="),
      values: sanitizeFilterValue(filter) || [],
    } as FilterSelection);
  });

  return filterSelections;
};

export const getTraceLink = (config: CustomLinkConfiguration) => {
  const filtersFromArtifact = getFilterSelectionsFromArtifact(config as TraceConfiguration);
  const filters = filtersFromArtifact.map((filter) => {
    if (filter.field === "StartTime" && !filter.values) {
      filter.values = ["3h"];
    }

    return filter;
  });

  const generatedLink = `https://portal.microsoftgeneva.com/${generateTraceExplorerLink({ filters })}`;
  return generatedLink;
};

const generateTraceExplorerLink = (state: DataGridBladeUrlState) => {
  const serializer = new TraceExplorerUrlSerializer();
  return serializer.serialize(state);
};

const serializeArray = (arr: string[] | string): string | string[] =>
  Array.isArray(arr) ? (arr.length > 1 ? arr : arr[0]) : arr;

const encodeFilterValues = (values: string | string[]): string | string[] => {
  return typeof values === "string" ? encodeURIComponent(values) : values.map((value) => encodeURIComponent(value));
};

class DataGridBladeUrlSerializer {
  private filtersToExtract: Set<string>;

  /**
   * Constructor
   * @param queryVariableNameMap Map of standalone variables to be extracted from filter
   * For example {time: "StartTime"}
   */
  constructor(
    private queryVariableNameMap: { [key: string]: string } = {},
    private blobSettings: BlobVariableSettings = {
      variableName: "state",
      searchVariableName: "_search",
      sortVariableName: "_sort",
      pageVariableName: "_page",
    }
  ) {
    this.filtersToExtract = Object.values(queryVariableNameMap).reduce((accum, value) => {
      accum.add(value);

      return accum;
    }, new Set<string>());
  }

  organizeState(state: DataGridBladeUrlState): DataGridBladeOrganizedState {
    const { filters = [], search, sort, page, params } = state;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const output: any = {};

    /**
     * 1. Extracting specified filters to separate URL variables
     * Note: The operator for these filters is ignored, when deserializing we assume it's a default one
     */
    for (const key in this.queryVariableNameMap) {
      if (Object.prototype.hasOwnProperty.call(this.queryVariableNameMap, key)) {
        const value = this.queryVariableNameMap[`${key}`];
        const found = filters.find((filter) => filter.field === value);
        if (found) {
          output[`${key}`] = serializeArray(found.values);
        }
      }
    }

    /**
     * 2. Assembling "blob" query variable
     */
    const blob: Array<Array<string | string[]>> = [];

    // removing filters we've extracted in previous step
    filters
      .filter((filter) => !Boolean(this.filtersToExtract.has(filter.field)))
      .forEach((filter) => {
        blob.push([filter.field, encodeURIComponent(filter.operator || "=="), encodeFilterValues(filter.values) || []]);
      });

    if (search) {
      blob.push([this.blobSettings.searchVariableName, encodeURIComponent(search)]);
    }

    if (sort) {
      blob.push([this.blobSettings.sortVariableName, sort.field, sort.direction]);
    }

    if (page) {
      blob.push([this.blobSettings.pageVariableName, String(page)]);
    }

    if (blob.length > 0) {
      output[this.blobSettings.variableName] = blob;
    }

    // other params
    params?.forEach((param) => {
      output[param.name] = param.value;
    });

    return output as DataGridBladeOrganizedState;
  }

  serialize(state: DataGridBladeUrlState): string {
    const output = this.organizeState(state);

    return this.innerSerialize(output);
  }

  private innerSerialize(state: DataGridBladeOrganizedState): string {
    const str: string[] = [];
    const entries = Object.entries(state);
    const numberOfEntries = entries.length;

    Object.entries(state).forEach(([key, value], index) => {
      str.push(key);
      str.push("=");

      if (Array.isArray(value)) {
        value = JSON.stringify(value);
      }

      str.push(value);

      if (index < numberOfEntries - 1) {
        str.push("&");
      }
    });

    return str.join("");
  }
}

class TraceExplorerUrlSerializer extends DataGridBladeUrlSerializer {
  constructor() {
    super(
      {
        time: "StartTime",
        endpoint: "Endpoint",
        namespace: "Namespace",
      },
      {
        variableName: "state",
        searchVariableName: "_search",
        sortVariableName: "_sort",
        pageVariableName: "_page",
      }
    );
  }
}

interface BlobVariableSettings {
  variableName: string;
  searchVariableName: string;
  sortVariableName: string;
  pageVariableName: string;
}

interface QueryParam {
  name: string;
  value: string;
}
/**
 * State kept in the URL
 */
interface DataGridBladeUrlState {
  filters?: FilterSelection[];
  search?: string;
  sort?: DataGridBladeSort;
  page?: number;
  // other params, which are not filters nor state)
  params?: QueryParam[];
}

interface DataGridBladeSort {
  field: string;
  direction: "asc" | "desc";
}

interface DataGridBladeOrganizedState {
  endpoint: string;
  namespace: string | string[];
  time: string | string[];
  state: Array<Array<string | string[]>>;
}
