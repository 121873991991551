import * as d3 from "d3";
import { HealthNode, LayoutOrientation } from "panel/types";

const maxLabelLength = 40;

export const rootOffset = 15;
export const maxNodeHeight = 45;
export const nodeIndent = 30;
export const margin = 8;
export const nodeHeight = maxNodeHeight - 15;

export const chevronRadius = 5;
export const verticalChevron = "M0 -5 L5 0 L0 5";
export const verticalExpandedChevron = "M-5 0 L0 5 L5 0";

const calcLevelWidth = (level: number, node: d3.HierarchyNode<HealthNode>, levelWidth: number[]) => {
  if (node.children && node.children.length > 0) {
    if (levelWidth.length <= level + 1) levelWidth.push(0);
    levelWidth[level + 1] += node.children.length;
    node.children.forEach((d) => {
      calcLevelWidth(level + 1, d, levelWidth);
    });
  }
};

const calcNodes = (node: d3.HierarchyNode<HealthNode>): number => {
  if (node.children && node.children.length > 0) {
    let score = 1;
    for (const child of node.children) {
      score += calcNodes(child);
    }
    return score;
  }
  return 1;
};

const getTreeData = (height: number, width: number, root: d3.HierarchyNode<HealthNode>) => {
  const tree = d3.tree<HealthNode>().size([height, width]);
  return tree(root);
};

export const layout = (
  width: number,
  height: number,
  rootData: HealthNode,
  orientation: LayoutOrientation
): [
  d3.HierarchyPointNode<HealthNode>[],
  d3.HierarchyPointNode<HealthNode>[],
  d3.HierarchyPointNode<HealthNode>[],
  d3.HierarchyPointLink<HealthNode>[],
  d3.HierarchyPointNode<HealthNode>,
  number
] => {
  const root = d3.hierarchy(rootData);
  if (orientation === "vertical") {
    const totalNodes = calcNodes(root);
    const newHeight = totalNodes * maxNodeHeight + rootOffset;
    const treeData = getTreeData(newHeight, width, root);
    const nodesSort: d3.HierarchyPointNode<HealthNode>[] = [];
    let ind = 0;
    treeData.eachBefore((n) => {
      n.data.preOrderIndex = ind++;
      nodesSort.push(n);
    });
    const nodes = nodesSort.filter((n) => n.data.type !== "loadMore");
    const loadMoreNodes = nodesSort.filter((n) => n.data.type === "loadMore");
    const links = treeData
      .links()
      .filter((l) => l.source.data.type !== "loadMore" && l.target.data.type !== "loadMore");
    nodesSort.forEach((n, i) => {
      n.x = i * maxNodeHeight;
      n.y = n.depth * nodeIndent;
    });
    return [nodesSort, nodes, loadMoreNodes, links, root as d3.HierarchyPointNode<HealthNode>, newHeight];
  } else {
    const levelWidth = [1];
    calcLevelWidth(0, root, levelWidth);

    const maxLevelWidth = d3.max(levelWidth) || 1;
    const newHeight = (maxLevelWidth + levelWidth.length) * maxNodeHeight;
    const treeData = getTreeData(newHeight, width, root);
    const allNodes = treeData.descendants();
    allNodes.forEach(function (d) {
      d.y = d.depth * (maxLabelLength * 10);
    });
    const nodes = allNodes.filter((n) => n.data.type !== "loadMore");
    const loadMoreNodes = allNodes.filter((n) => n.data.type === "loadMore");
    const links = treeData
      .links()
      .filter((l) => l.source.data.type !== "loadMore" && l.target.data.type !== "loadMore");
    return [allNodes, nodes, loadMoreNodes, links, root as d3.HierarchyPointNode<HealthNode>, height];
  }
};

export const getRootGroupTransform = (orientation: LayoutOrientation) => {
  if (orientation === "horizontal") {
    return "";
  }
  return `translate(${rootOffset}, ${rootOffset})`;
};
