import { GrafanaTheme2 } from "@grafana/data";
import { Alert, useTheme2 } from "@grafana/ui";
import { mergeStyleSets } from "@uifabric/merge-styles";
import React from "react";
import { Warning } from "./hooks/useWarning";

interface WarningsProps {
  warnings: Record<string, Warning>;
  removeWarning: (key: string) => void;
}

const getStyles = (theme: GrafanaTheme2) => {
  return mergeStyleSets({
    warning: {
      selectors: {
        // this is for the warning icon
        "> div:first-child": {
          padding: theme.spacing(0, 0.5, 0, 0.5),
        },
        // this is for the warning icon
        "> div:nth-child(2)": {
          padding: theme.spacing(1, 1, 1, 1),
        },
        "> div:nth-child(2) > div:nth-child(2)": {
          padding: theme.spacing(0, 0, 0, 0),
        },
        "> div:nth-child(2) > div:nth-child(2) > p": {
          margin: theme.spacing(0, 0, 0, 0),
        },
      },
      fontFamily: theme.typography.fontFamilyMonospace,
      fontSize: theme.typography.bodySmall.fontSize,
      fontWeight: theme.typography.bodySmall.fontWeight,
      color: theme.colors.text.maxContrast,
    },
    warnings: {
      padding: theme.spacing(2, 0, 0, 0),
    },
  });
};

export const Warnings: React.FC<WarningsProps> = ({ warnings, removeWarning }) => {
  const styles = getStyles(useTheme2());

  return (
    <div className={styles.warnings}>
      {warnings &&
        Object.entries(warnings)
          .sort((a: [string, Warning], b: [string, Warning]) => (a[1].order ?? 0) - (b[1].order ?? 0))
          .map(([key, val], _) => {
            return (
              <Alert
                title=""
                className={styles.warning}
                severity={!!val.isInfo ? "info" : "warning"}
                key={key}
                onRemove={() => removeWarning(key)}
              >
                {val.message.split("\n").map((line, _index) => (line ? <p>{line}</p> : <br />))}
              </Alert>
            );
          })}
    </div>
  );
};
