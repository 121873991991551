import { DataSourceInstanceSettings, TimeRange, toDuration } from "@grafana/data";
import { ContextMenu, MenuItem, Modal } from "@grafana/ui";
import { healthLoader } from "api/health/healthLoader";
import { trackPageView } from "appInsights";
import { Suppress, Unsuppress } from "panel/Suppress";
import { ContextMenuItem, HealthNode, Point, SuppressionMode } from "panel/types";
import React, { useEffect, useState } from "react";
import { GenevaJsonData } from "types";
import { TreeStore } from "./stores/TreeStore";
import { getDefaultMenuItems, getEnrichments, SuppressKey } from "./utils/menuItemUtils";

interface ContextMenuProps {
  account: string;
  node: HealthNode;
  point: Point;
  show: boolean;
  dataSourceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  onClose: () => void;
}

export const NodeContextMenuView = ({ show, account, node, point, dataSourceSettings, onClose }: ContextMenuProps) => {
  const { setState } = TreeStore.useState();
  const [menuItems, setMenuItems] = useState<ContextMenuItem[]>([]);
  const [showSuppress, setShowSuppress] = useState<boolean>(false);
  const [suppressionMode, setSuppressionMode] = useState<SuppressionMode>();
  const [suppressStateChange, setSuppressStateChange] = useState<boolean>(false);
  useEffect(() => {
    setSuppressionMode(node.suppressionState);
  }, [node.suppressionState]);
  useEffect(() => {
    if (show) {
      setState((state) => {
        return {
          ...state,
          rightClickedNodeRefresh: node,
        };
      });
      trackPageView({
        name: "NodeContextMenuView",
        properties: {
          account: account,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    setMenuItems([]);
    const getContextMenuItems = async () => {
      const items = getDefaultMenuItems(node);
      const suppressItem = items.find((item) => item.key === SuppressKey);
      if (suppressItem) {
        suppressItem.onClick = () => {
          setShowSuppress(true);
        };
      }
      setMenuItems(items);
      const enrichments = await getEnrichments(node, account, dataSourceSettings);
      if (enrichments && enrichments.length > 0) {
        setMenuItems([...items, ...enrichments]);
      }
    };
    getContextMenuItems();
  }, [account, dataSourceSettings, node]);

  const renderMenuItems = () => {
    return menuItems.map((item) => (
      <MenuItem
        ariaLabel=""
        icon={(item.getIcon ? item.getIcon(node) : item.icon) as never}
        url={item.url || (item.getUrl ? item.getUrl(account || "", node) : "")}
        target={item.linkTarget}
        label={item.getLabel ? item.getLabel(node) : item.label}
        onClick={item.onClick}
      />
    ));
  };
  const modalTitle = `${node.suppressionState === SuppressionMode.Unsuppress ? "Suppress" : "Reinstate"} alerts from ${
    node.name
  }`;
  const onCloseModal = () => {
    setShowSuppress(false);
    if (suppressStateChange) {
      setState((state) => {
        return {
          ...state,
          updateSuppressionNode: node,
        };
      });
    }
    onClose();
  };
  const onSuppress = async (timeRange: TimeRange) => {
    const diff = timeRange.to.diff(timeRange.from);
    const duration = toDuration(diff);
    if (node && node.suppressionState === SuppressionMode.Unsuppress) {
      await healthLoader.setSuppression(dataSourceSettings, account || "", node.id, duration.asSeconds(), false);
      node.suppressionState = SuppressionMode.Suppress;
      setSuppressionMode(SuppressionMode.Suppress);
      setSuppressStateChange(true);
    }
  };

  const onReinstate = async () => {
    if (node && node.suppressionState === SuppressionMode.Suppress) {
      await healthLoader.setSuppression(dataSourceSettings, account || "", node.id, 0, true);
      node.suppressionState = SuppressionMode.Unsuppress;
      setSuppressionMode(SuppressionMode.Unsuppress);
      setSuppressStateChange(true);
    }
  };

  return (
    <>
      {!showSuppress && show && node && point && (
        <ContextMenu
          renderMenuItems={renderMenuItems}
          x={point.x}
          y={point.y}
          onClose={() => {
            setState((state) => {
              return {
                ...state,
                rightClickedNodeRefresh: {} as HealthNode,
              };
            });
            onClose();
          }}
        ></ContextMenu>
      )}
      <Modal title={modalTitle} isOpen={showSuppress} onDismiss={onCloseModal}>
        {suppressionMode === SuppressionMode.Unsuppress ? (
          <Suppress onApply={onSuppress} onCancel={onCloseModal} />
        ) : (
          <Unsuppress account={account} node={node} onApply={onReinstate} onCancel={onCloseModal}></Unsuppress>
        )}
      </Modal>
    </>
  );
};
